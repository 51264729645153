import $, { css, get } from "jquery";
import "@fancyapps/fancybox";
import "slick-carousel";
import "magnific-popup/dist/jquery.magnific-popup.min";

$(function ($) {


    // Function for updating the final price and name
    function updateTotalPriceAndName() {
        const giveAwayPrice = parseFloat($('#giveAwayList .select button').attr('data-price'));
        const receiveAwayPrice = parseFloat($('#receiveAwayList .select button').attr('data-price'));
        const giveAwaydataName = $('#giveAwayList .select button').attr('data-name');
        const receiveAwaydataName = $('#receiveAwayList .select button').attr('data-name');
        let total = 0;
        // total = giveAwayPrice * receiveAwayPrice;

        if (giveAwaydataName == 'currency') {
            total = giveAwayPrice / receiveAwayPrice;

        } else {

            total = giveAwayPrice * receiveAwayPrice;
        }

        $('#selectCryptoPrice').text(total.toFixed(6)); 


        let receiveAwayName = $('#receiveAwayList .select button').attr('type-name');
        $('#selectCryptoPriceName, .selectCryptoPriceName').text(receiveAwayName);
    }
    // We call the function when the page is loaded
    updateTotalPriceAndName();
    // hideListItemBasedOnSelectedTypeName();
    // We call the function for processing clicks
    $("#giveAwayList li").on('click', function () {
        $("#giveAwayList li").removeClass("select");
        $(this).addClass('select');

        var $button = $(this).find("button");
        // var liClass = $(this).attr('class');
        // var liClassNoSelect = liClass.removeClass('select');
        var liClasses = Array.from(this.classList).filter(function (className) {
            return className !== 'select';
        });
        var name = $button.attr("type-name");
        var minCurrencyMap = {
            'USD': 1000,
            'UAH': 38000,
            'TRX': 1000,
            'Ethereum': 0.4,
            'EUR': 1000,
            'Bitcoin': 0.025,
            'USDT TRC20': 1000,
            'USDT ERC20': 1000,
            'Privat24': 38000
        };

        var minCurrency = minCurrencyMap[name] || 0;
        // We set the name and price in the corresponding elements.
        $("#selectCryptoName,.selectCryptoName").text(name);
        $(".min_currency").text('від ' + minCurrency);

        $(".cryptoFrom").val('');
        $(".cryptoTo").val('');

        updateTotalPriceAndName();
        // hideListItemBasedOnSelectedTypeName();
        receiveAwayListAllBlock(liClasses);
        // $('#receiveAwayList li.BTCTUSD').addClass('select');
        // $('#receiveAwayList li.USD').removeClass('select');
    });

    $("#receiveAwayList li").on('click', function () {
        $("#receiveAwayList li").removeClass("select");
        $(this).addClass('select');

        var $button = $(this).find("button");
        var liClasses = Array.from(this.classList).filter(function (className) {
            return className !== 'select';
        });
        var name = $button.attr("type-name");
        // var price = $button.attr("data-price");

        // We set the name and price in the respective elements.
        $("#selectCryptoPriceName").text(name);

        $(".cryptoFrom").val('');
        $(".cryptoTo").val('');
        updateTotalPriceAndName();
        // showListItemBasedOnSelectedTypeName(name);
        giveAwayListAllBlock(liClasses);
    });


    // Event handler for selection in the "Віддаєте" section
    $('#giveAwayList button').on('click', () => {
        $('#giveAwayList .select').removeClass('select');
        $(this).parent().addClass('select');

        updateTotalPriceAndName();
    });

    // Event handler for selection in the "Отримуєте" section
    $('#receiveAwayList button').on('click', () => {
        $('#receiveAwayList .select').removeClass('select');
        $(this).parent().addClass('select');
        updateTotalPriceAndName();
    });



    $('#receiveAwayList li').each(function () {        
        // Check if the element is 'USD' and visible
        if ($(this).hasClass('USD') && $(this).css('display') != 'none') {
            // If visible, add the 'select' class
            $(this).addClass('select');
        } else if ($(this).hasClass('BTCTUSD') && $(this).css('display') == 'none') {
            // For 'BTCTUSD', directly add the 'select' class without visibility condition
            $(this).addClass('select');
        }
    });
    $('.cryptoFrom').on('input', function () {

        const giveAwaydataName = $('#giveAwayList .select button').attr('data-name');
        const receiveAwaydataName = $('#receiveAwayList .select button').attr('data-name');
        const giveTypeName = $('#giveAwayList .select button').attr('type-name');
        const giveAwayPrice = parseFloat($('#giveAwayList .select button').attr('data-price'));
        const receiveAwayPrice = parseFloat($('#receiveAwayList .select button').attr('data-price'));
        const receiveTypeName = $('#receiveAwayList .select button').attr('type-name');
        let updatedValue;
        let inputVal = $(this).val();
        var selectCryptoPrice = parseFloat($('#selectCryptoPrice').text());

        if (!isNaN(inputVal) && !isNaN(selectCryptoPrice)) {
            $('.cryptoFrom').val(inputVal);
            updatedValue = inputVal * selectCryptoPrice;
            // Define conversion rules for different currencies and coins
            const conversionRules = {
                'Bitcoin': {
                    'USD': 6,
                    'UAH': 6,
                    'EUR': 6
                },
                'Ethereum': {
                    'USD': 4,
                    'UAH': 4,
                    'EUR': 4
                },
                'TRX': {
                    'USD': 2,
                    'UAH': 2,
                    'EUR': 2
                }
            };
            // Get the conversion precision based on the given currency and coin
            let precision = conversionRules[receiveTypeName] && conversionRules[receiveTypeName][giveTypeName];
            // If precision is not defined, fallback to 2
            precision = precision || 2;
            // Update the value based on the precision
            $('.cryptoTo').val(updatedValue.toFixed(precision));

            // $('#crypto-from').val(inputVal);
            // $('#crypto-to').val(updatedValue.toFixed(8));
        } else {
            $('.cryptoFrom, .cryptoTo').val('');
        }
    });


    function hideListItemBasedOnSelectedTypeName() {
        // Find the selected type-name within the selected class
        var selectedTypeName = $(".select button").attr("type-name");

        // If a selected type-name is found
        if (selectedTypeName) {
            // Iterate through each list item
            $("#receiveAwayList li").each(function () {
                // Find the button within the list item
                var button = $(this).find("button");

                // Check if the button has the selected type-name
                if (button.attr("type-name") === selectedTypeName) {
                    // If it does, hide the list item
                    $(this).hide();
                } else {
                    // If it doesn't, show the list item
                    $(this).show();
                }
            });
        }
    }

    function receiveAwayListAllBlock(classCurrency) {

        $("#giveAwayList li").each(function () {
            $(this).show();
        });
        $("#receiveAwayList li").each(function () {

            if ($(this).hasClass(classCurrency)) {

                $(this).hide();
            } else {
                // If it doesn't, show the list item
                $(this).show();
            }
        });
    }
    function giveAwayListAllBlock(classCurrency) {
        // console.log(classCurrency);
        $("#receiveAwayList li").each(function () {
            $(this).show();
        });
        // Iterate through each list item
        $("#giveAwayList li").each(function () {
            if ($(this).hasClass(classCurrency)) {
                $(this).hide();
            } else {
                $(this).show();
            }
        });
    }


    function showListItemBasedOnSelectedTypeName(name) {
        // Find the selected type-name within the selected class
        var selectedTypeName = name;
        // If a selected type-name is found
        if (selectedTypeName) {
            // Iterate through each list item
            $("#giveAwayList li").each(function () {
                // Find the button within the list item
                var button = $(this).find("button");

                // Check if the button has the selected type-name
                if (button.attr("type-name") === selectedTypeName) {
                    // If it does, show the list item
                    $(this).hide();
                } else {
                    // If it doesn't, hide the list item
                    $(this).show();

                }
            });
        }
    }
    /**
     * GNS-IT
     */

    //  $('#openPopupButton').on('click', function () {
    //     // Trigger Magnific Popup on button click
    //     $.magnificPopup.open({
    //         items: {
    //             src: '#crypto-popup'
    //         },
    //         type: 'inline'
    //     });
    // });
    $('.crypto-popup-button').magnificPopup({
        items: {
            src: '#popup-content',
            type: 'inline'
        },
        removalDelay: 500,
        fixedContentPos: true,
        mainClass: 'popupClassOne',
        callbacks: {
            beforeOpen: function () {
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        }
    });


    $('.payment-methods__item').on('click', function () {
        // Remove 'active' class from all items
        $('.payment-methods__item').removeClass('active');

        // Add 'active' class to the clicked item
        $(this).addClass('active');
        var paymentMethods = $('.active .payment-methods__name').text();
        $('#payment-methods').val(paymentMethods);
    });


    // Function to retrieve and display data
    const displayData = (selector, targetContainer) => {
        let dataContent = '';

        $(selector).each(function () {
            const typeName = $(this).attr('type-name');
            const imgSrc = $(this).find('img').attr('src');

            // Update the content of the target container
            $(`${targetContainer} img`).attr('src', imgSrc);
            $(`${targetContainer} .coin-popup__name`).text(typeName);
        });
    };




    $('.crypto-popup-button').on('click', function () {
        // Call the function with the desired selector and container
        displayData('#giveAwayList li.select button', '.coin-popup-give');
        displayData('#receiveAwayList li.select button', '.coin-popup-receive');
        // Get data from cryptoFrom field


        // Get coin-popup__name from coin-popup__content
        var coinGivePopupName = $('.coin-popup-give .coin-popup__content .coin-popup__name').text();
        var coinReceivePopupName = $('.coin-popup-receive .coin-popup__content .coin-popup__name').text();

        // Write coin-popup__name to crypto-to-coin
        $('#crypto-from-coin').val(coinGivePopupName);
        $('#crypto-to-coin').val(coinReceivePopupName);
    });


    $('#seo-toggle').on('click', function () {
        var $content = $('.seo-text__wrap');
        var $btnContainer = $('.btn-container');
        $content.toggleClass('seo-expanded');
        var isExpanded = $content.hasClass('seo-expanded');

        if (isExpanded) {
            $content.slideDown();
            $btnContainer.hide();
            $content.parent().parent().addClass('seo-text__expanded');
        }
    });
});
